<template>
  <v-card class="pa-2" flat>
    <v-card-title class="pb-0">
      {{ title }}
    </v-card-title>
    <v-row no-gutters justify="space-between" class="pt-2">
      <v-col cols="5">
        <v-row no-gutters>
          <v-text-field
            v-model="testString"
            dense
            outlined
            required
            :label="$lang.labels.input"
          ></v-text-field>
          <v-btn
            color="primary"
            class="ml-1"
            @click="testRegex()"
          >
            {{ $lang.actions.test }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="5" class="ml-3">
        <v-text-field
          v-model="testResult"
          dense
          outlined
          required
          readonly
          :label="$lang.labels.inputMatchesRegex"
        ></v-text-field>
      </v-col>
    </v-row>
    <prism-editor v-model="code" class="my-editor" :highlight="highlighter" line-numbers></prism-editor>
    <v-card-title v-if="withButtons" class="pt-2">
      <v-btn
        color="primary"
        text
        @click="$emit('closeDialog', true)"
      >
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="save()"
      >
        {{ $lang.actions.save }}
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  components: {
    PrismEditor
  },
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    withButtons: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({ code: '', testString: '', testResult: '' }),
  watch: {
    code: {
      handler(val) {
        if (!this.withButtons) this.$emit('fromGlobalEditor', val)
      }
    }
  },
  created() {
    if (this.item) this.code = this.item.value
  },
  methods: {
    testRegex() {
      const regex = new RegExp(this.code)

      this.testResult = regex.test(this.testString) ? this.$lang.labels.yes : this.$lang.labels.no
    },
    highlighter(code) {
      return highlight(code, languages.js) //returns html
    },
    save() {
      this.$emit('fromGlobalEditor', this.code)
      this.$emit('closeDialog', true)
    }
  }
}
</script>
<style lang="scss" scoped>

@font-face {
  font-family: 'Fira code';
  src: local('fira code'), url('~@/assets/fonts/FiraCode-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url('~@/assets/fonts/FiraMono-Regular.ttf') format('truetype');
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 12px;
  height: 50vh;
  overflow-y: auto;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
