<template>
  <v-container fluid>
    <div class="pb-4">
      <h1 class="text-h4">{{ $lang.header.validationDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="fill-height">
        <v-col
          cols="12"
          sm="12"
          md="10"
        >
          <v-alert v-if="!userCanEdit && !loading" dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="10"
          class="fill-height"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-2 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-1"
                >
                  <v-row wrap no-gutters justify="space-between" class="pb-1">
                    <v-col
                      cols="12"
                      class="pr-sm-1"
                    >
                      <v-row wrap no-gutters justify="space-between" class="pb-1">
                        <div class="d-flex">
                          <v-text-field
                            v-model="validation.name"
                            outlined
                            dense
                            :label="$lang.labels.name"
                            required
                            class="required-asterisk"
                            :rules="[v => !!v || 'Required!']"
                            :persistent-hint="isEdit"
                            :hint="formatHint"
                            :readonly="!userCanEdit || validation.isSystem"
                            data-cy="validation-name"
                            style="min-width: 450px"
                          ></v-text-field>
                          <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                @click="copyCompleteValidation()"
                                v-on="on"
                              >
                                <v-icon>mdi-clipboard-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Copy to clipboard</span>
                          </v-tooltip>
                        </div>
                        <v-btn
                          :disabled="lock || !userCanEdit"
                          icon
                          color="primary"
                          @click="showImport = true"
                        >
                          <v-icon>mdi-import</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <v-checkbox
                        v-model="includesAnotherValidation"
                        :label="$lang.labels.includesAnotherValidation"
                        hide-details
                        class="mt-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      v-if="includesAnotherValidation"
                      cols="12"
                      sm="9"
                      class="pr-sm-1 mt-2"
                    >
                      <v-autocomplete
                        v-model="validation.includes"
                        outlined
                        dense
                        :items="allValidations"
                        chips
                        multiple
                        clearable
                        closable-chips
                        hide-no-data
                        hide-selected
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.validations"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        :readonly="!userCanEdit"
                        data-cy="includes-validations"
                        class="required-asterisk"
                        :rules="[v => !!v || 'Required!']"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            small
                            v-bind="data.attrs"
                            close
                            @click="openValidation(data.item.id)"
                            @click:close="remove(data.item)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="includesAnotherValidation"
                      cols="12"
                      class="pr-sm-1 mb-1"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" class="pb-2">
                    <v-col cols="12" class="pb-1">
                      <div class="d-inline-flex justify-space-between" style="width: 100%; padding-right: 3px">
                        <h4 class="text-h5">{{ $lang.labels.fields }}</h4>
                        <div>
                          <v-btn
                            icon
                            color="primary"
                            class="ml-2"
                            style="margin-top: 2px"
                            title="copy"
                            @click="copyFields()"
                          >
                            <v-icon>mdi-clipboard-outline</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            color="primary"
                            class="ml-2"
                            style="margin-top: 2px"
                            title="paste"
                            @click="pasteFields()"
                          >
                            <v-icon>mdi-clipboard</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row v-if="!loading" wrap no-gutters>
                    <v-col cols="12">
                      <template v-for="(item, i) in validation.fields">
                        <v-card
                          :key="i"
                          elevation="1"
                          outlined
                          class="mb-2 pa-2 pb-0"
                        >
                          <v-row no-gutters align="start">
                            <v-col cols="12" class="d-flex">
                              <div class="d-inline-flex align-center justify-space-between" style="width: 100%">
                                <div class="d-inline-flex align-center">
                                  <h4 class="color-primary">{{ i + 1 }}.</h4>
                                  <v-text-field
                                    v-model="item.name"
                                    dense
                                    :readonly="!userCanEdit"
                                    :label="$lang.labels.fieldName"
                                    required
                                    outlined
                                    class="pl-2 required-asterisk"
                                    :rules="[v => !!v || 'Required!']"
                                    :data-cy="`field-name-${i}`"
                                  ></v-text-field>
                                  <v-select
                                    v-model="item.dataType"
                                    :items="formattedDataTypes"
                                    :label="$lang.labels.dataType"
                                    outlined
                                    dense
                                    required
                                    item-text="text"
                                    item-value="value"
                                    class="pl-2 required-asterisk"
                                    :rules="[v => !!v || 'Required!']"
                                    :readonly="!userCanEdit"
                                    style="min-width: 115px"
                                    :data-cy="`field-dataType-${i}`"
                                    @change="['BOOLEAN', 'TIMESTAMP', 'INTEGER', 'FILE_JSON', 'FILE_XML', 'ARRAY'].includes(item.dataType) ? (item.rules = [], item.genericSubType = '', item.validationRuleId = null) : ['OBJECT'].includes(item.dataType) ? (item.rules = [], item.genericSubType = 'OBJECT') : item.genericSubType = ''"
                                  />
                                  <v-select
                                    v-if="['ARRAY'].includes(item.dataType)"
                                    v-model="item.genericSubType"
                                    :items="item.dataType === 'ARRAY' ? formattedDataSubTypes : formattedObjectDataSubTypes"
                                    :label="$lang.labels.genericSubType"
                                    outlined
                                    dense
                                    item-text="text"
                                    item-value="value"
                                    class="pl-2 required-asterisk"
                                    :readonly="!userCanEdit"
                                    :persistent-hint="item.genericSubType === 'OBJECT'"
                                    :hint="item.genericSubType === 'OBJECT' ? $lang.hints.subType : ''"
                                    style="min-width: 115px"
                                    :data-cy="`field-genericSubType-${i}`"
                                  />
                                </div>
                              </div>
                              <div cols="1">
                                <v-icon
                                  class="d-block"
                                  data-direction="up"
                                  :disabled="parseInt(`${i}`) === 0"
                                  :data-index="`${i}`"
                                  style="font-size: 20px"
                                  @click="moveItemsAround"
                                >mdi mdi-arrow-up
                                </v-icon>
                                <v-icon
                                  class="d-block"
                                  data-direction="down"
                                  :disabled="parseInt(`${i}`) === validation.fields.length - 1"
                                  :data-index="`${i}`"
                                  style="font-size: 20px"
                                  @click="moveItemsAround"
                                >
                                  mdi mdi-arrow-down
                                </v-icon>
                              </div>
                            </v-col>
                            <v-col
                              v-if="['ARRAY', 'OBJECT'].includes(item.dataType) && item.genericSubType === 'OBJECT'"
                              cols="12"
                              lg="6"
                              md="7"
                              sm="8"
                              class="pl-4 mt-2"
                            >
                              <div class="d-inline-flex" style="width: 100%">
                                <v-autocomplete
                                  v-model="item.validationRuleId"
                                  outlined
                                  dense
                                  :items="allValidations"
                                  clearable
                                  hide-no-data
                                  item-text="name"
                                  item-value="id"
                                  :label="$lang.labels.validation"
                                  :placeholder="$lang.actions.startTyping"
                                  prepend-inner-icon="mdi-cloud-search-outline"
                                  :readonly="!userCanEdit"
                                  :data-cy="`field-genericSubTypeValidation-${i}`"
                                  :rules="[v => !!v || 'Required!']"
                                  class="required-asterisk"
                                ></v-autocomplete>
                                <v-btn
                                  icon
                                  color="primary"
                                  class="ml-1"
                                  @click="searchValidationsFunction(item, i)"
                                >
                                  <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-btn
                                  text
                                  class="ml-1"
                                  color="primary"
                                  :disabled="(!item.validationRule || (item.validationRule && !item.validationRule.id)) && !item.validationRuleId"
                                  @click="openValidation(item.validationRuleId || item.validationRule.id)"
                                >
                                  {{ $lang.actions.openTemplate }}
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <v-row no-gutters align="center" class="pb-1 pl-4 mt-3">
                                <v-col cols="12" class="pb-1">
                                  <div class="d-inline-flex align-center" style="width: 100%; padding-right: 17px">
                                    <h5 class="text-h6">{{ $lang.labels.rules }}</h5>
                                    <v-btn
                                      color="accent"
                                      small
                                      :disabled="!userCanEdit || !item.dataType || item.rules.length > 1 || ['BOOLEAN', 'TIMESTAMP', 'OBJECT'].includes(item.dataType)"
                                      data-cy="add-new-rule"
                                      class="ml-2 pl-1 color-accent-text"
                                      @click="item.rules.push({ type: '', value: '' })"
                                    >
                                      <v-icon color="black">mdi-plus</v-icon>
                                      {{ $lang.actions.addRule }}
                                    </v-btn>
                                  </div>
                                </v-col>
                                <v-col cols="12">
                                  <v-card
                                    v-if="item.rules.length"
                                    elevation="10"
                                    outlined
                                    class="pa-1"
                                  >
                                    <template v-for="(rule, n) in item.rules">
                                      <v-row :key="10000 + n" no-gutters>
                                        <v-col
                                          cols="12"
                                          md="3"
                                        >
                                          <v-select
                                            v-model="rule.type"
                                            :items="formattedRuleTypes(i, n)"
                                            :label="$lang.labels.ruleType"
                                            outlined
                                            dense
                                            required
                                            class="pr-2"
                                            :readonly="!userCanEdit || !item.dataType"
                                            :data-cy="`rule-ruleType-${n}`"
                                          ></v-select>
                                        </v-col>
                                        <v-col
                                          cols="11"
                                          lg="8"
                                          md="9"
                                          sm="10"
                                          style="position: relative"
                                        >
                                          <v-text-field
                                            v-model="rule.value"
                                            dense
                                            outlined
                                            :readonly="!userCanEdit"
                                            required
                                            :type="['NUMBER', 'INTEGER', 'DOUBLE', 'ARRAY', 'LONG'].includes(item.dataType) ? 'number' : 'text'"
                                            :rules="[v => (!!v || v === 0 || v === '0') || 'Required!', v => isRegex(v, rule.type) || 'Invalid regex!']"
                                            :data-cy="`rule-value-${n}`"
                                            class="hideNumberArrows"
                                            @change="item.dataType === 'INTEGER' ? rule.value = Number(rule.value).toFixed() : ''"
                                          ></v-text-field>
                                          <v-btn
                                            v-if="rule.type === 'REGEX'"
                                            class="button-regex"
                                            style="position: absolute; top: 6px; right: 10px"
                                            icon
                                            small
                                            :disabled="!userCanEdit"
                                            @click="selectedItem = { text: '', value: rule.value || '', fieldIndex: i, ruleIndex: n }; showRegexEditor = true"
                                          >
                                            <v-icon small color="info">mdi-file-edit-outline</v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col xs="1" class="align-start pt-0 mt-0 pl-1 text-right">
                                          <v-btn
                                            icon
                                            color="error"
                                            @click="deleteRule(i, n)"
                                          >
                                            <v-icon size="25" color="red">mdi-trash-can-outline</v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12 pt-1">
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                          <v-card-actions class="d-flex justify-end pr-0">
                            <v-checkbox
                              v-model="item.isRequired"
                              class="pt-1 mr-3"
                              :label="$lang.labels.required"
                              :data-cy="`field-isRequired-${i}`"
                            ></v-checkbox>
                            <v-btn
                              icon
                              class="pa-"
                              color="error"
                              @click="deleteRow(i)"
                            >
                              <v-icon size="25" color="red">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                      <v-col align="end" class="pa-0 pb-5">
                        <v-btn
                          color="accent"
                          :disabled="!userCanEdit"
                          data-cy="add-new-field"
                          class="ml-2 pl-1 color-accent-text"
                          @click="validation.fields.push(JSON.parse(JSON.stringify(newField)))"
                        >
                          <v-icon color="black">mdi-plus</v-icon>
                          {{ $lang.actions.addField }}
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-2"></v-divider>
                  <v-row no-gutters wrap justify="space-between" class="pb-4">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          class="button-default-width"
                          color="secondary"
                          :to="{ name: 'validations', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="validation.name"
                          :title="$lang.actions.delete"
                          type="validation"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'validation-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteValidationFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'validations' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-progress-circular v-if="lock || loading" indeterminate :color="loading ? 'success' : 'accent'"></v-progress-circular>
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit"
                        color="success"
                        class="button-default-width ml-2"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreValidation($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-if="showRegexEditor" v-model="showRegexEditor" max-width="71%">
      <regex-editor
        :title="$lang.labels.regex"
        :item="selectedItem"
        :with-buttons="true"
        @closeDialog="showRegexEditor = false; selectedItem = null"
        @fromGlobalEditor="updateFromRegexEditor($event)"
      ></regex-editor>
    </v-dialog>
    <v-dialog v-if="showImport" v-model="showImport" max-width="80%" width="80%">
      <json-import
        v-if="showImport"
        type="VALIDATION_RULE"
        @import="showImport = false; importFunction($event)"
        @closeDialog="showImport = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Table from '@/components/ui/tables/HistoryDatatable'
import {
  getValidationRuleByIdUsingGET as getValidation,
  updateValidationRuleUsingPUT as updateValidation,
  createValidationRuleUsingPOST as createValidation,
  getRolesUsingGET as getRoles,
  getHistoriesUsingGET as getHistories,
  getValidationRulesUsingGET as getValidations,
  deleteValidationRuleUsingDELETE as deleteValidation
} from '@/utils/api'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import copy from 'copy-to-clipboard'
import RegexEditor from '@/components/ui/RegexEditor.vue'
import jsonImport from '@/components/ui/modals/JsonImport.vue'

export default {
  components: {
    jsonImport,
    RegexEditor,
    ActionButtonWithConfirmation,
    Table,
    UserRolesSelect
  },
  data() {
    return {
      showImport: false,
      showRegexEditor: '',
      selectedItem: null,
      err: '',
      success: '',
      testResult: '',
      resultColor: '',
      tab: 0,
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      validation: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        fields: [],
        includes: []
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      history: { items: [], meta: {} },
      dataTypes: ['NUMBER', 'STRING', 'BOOLEAN', 'TIMESTAMP', 'INTEGER', 'DOUBLE', 'FILE_JSON', 'FILE_XML', 'ARRAY', 'OBJECT', 'LONG'].sort(),
      genericSubTypeTypes: ['BOOLEAN', 'DOUBLE', 'INTEGER', 'NUMBER', 'STRING', 'TIMESTAMP', 'OBJECT', 'LONG'].sort(),
      genericObjectSubTypeTypes: ['OBJECT'].sort(),
      ruleTypesNumber: ['MAX_VALUE', 'MIN_VALUE'],
      ruleTypesString: ['MAX_LENGTH', 'MIN_LENGTH', 'REGEX'],
      ruleTypesFile: ['MIN_SIZE', 'MAX_SIZE'].sort(),
      newField: {
        dataType: '',
        genericSubType: '',
        isRequired: true,
        name: '',
        rules: [],
        validationRule: {},
        validationRuleId: '',
        searchValidations: '',
        validations: [],
        isLoadingValidations: false
      },
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      allValidations: [],
      userCanDelete: false,
      includesAnotherValidation: false
    }
  },
  computed: {
    formattedDataTypes () {
      return this.dataTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedDataSubTypes () {
      return this.genericSubTypeTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedObjectDataSubTypes () {
      return this.genericObjectSubTypeTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.validation.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.validation.modifiedOn)}, ID: ${this.validation.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  watch: {
    includesAnotherValidation: {
      handler(val) {
        if (val === false) {
          this.validation.includes = []
        }
      },
      deep: true
    }
  },
  created() {
    this.isEdit = this.$route.name === 'validationEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'VALIDATION_RULE_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        getValidations()
          .then((res) => {
            this.allValidations = res.data.data.items
          })
          .catch((err) => {
            this.err = err
          })
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getValidation({ id: this.$route.params.id })
            .then((res) => {
              this.validation = res.data.data

              if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
                this.includesAnotherValidation = true
              }

              this.validation.fields.forEach((field) => {
                if (field.validationRuleId) {
                  field.validationRule = {}

                  getValidation({ id: field.validationRuleId })
                    .then((res) => {
                      field.validationRule = res.data.data
                    })
                }
              })

              this.editRolesIds = this.validation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.validation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.validation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              this.loading = false
            })
            .catch((err) => {
              this.err = err
            })
        } else if (this.$route.params.copyFromModal) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.showImport = true
          this.loading = false
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.importFunction(this.$route.params.copy)
          }
          this.loading = false
        }
      })
  },
  methods: {
    importFunction(importData) {
      this.validation = importData

      if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
        this.includesAnotherValidation = true
      }

      this.validation.fields.forEach((field) => {
        if (field.validationRuleId) {
          field.validationRule = {}

          getValidation({ id: field.validationRuleId })
            .then((res) => {
              field.validationRule = res.data.data
            })
        }
      })
    },
    updateFromRegexEditor(value) {
      this.validation.fields[value.fieldIndex].rules[value.ruleIndex] = value.value
    },
    isRegex(str, type) {
      if (type !== 'REGEX') return true
      try {
        new RegExp(str)

        return true
      } catch (e) {
        return false
      }
    },
    moveItemsAround(e) {
      let toIndex = 0
      let canMove = true
      const clickedItemIndex = parseInt(e.target.dataset.index)
      const clickedDirection = e.target.dataset.direction

      if (clickedDirection === 'up') {
        if (clickedItemIndex !== 0) {
          toIndex = clickedItemIndex - 1
        } else {
          canMove = false
        }
      } else {
        if (clickedItemIndex !== this.validation.fields.length - 1) {
          toIndex = clickedItemIndex + 1
        } else {
          canMove = false
        }
      }

      if (canMove) {
        // eslint-disable-next-line prefer-destructuring
        const clickedEl = this.validation.fields.splice(clickedItemIndex, 1)[0]

        this.validation.fields.splice(toIndex, 0, clickedEl)
      }
    },
    remove (item) {
      const index = this.validation.includes.indexOf(item.id)

      if (index >= 0) this.validation.includes.splice(index, 1)
    },
    deleteValidationFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteValidation({ id: this.validation.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    copyFields() {
      localStorage.setItem('copiedData', JSON.stringify(this.validation.fields))
    },
    copyCompleteValidation() {
      copy(this.validation.name)
      this.success = 'Copied'
      setTimeout(() => {
        this.success = ''
      }, 3000)
    },
    pasteFields() {
      let pasteData = localStorage.getItem('copiedData')

      pasteData = JSON.parse(pasteData)

      if (pasteData.length) {
        // eslint-disable-next-line array-callback-return
        pasteData.map((item) => {
          this.validation.fields.push(item)
        })
      }
    },
    openValidation(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/validations/edit/${id}`, '_blank')
    },
    searchValidationsFunction(item, i) {
      this.validation.fields[i].isLoadingValidations = true

      const obj = {}

      if (this.validation.fields[i].searchValidations && this.validation.fields[i].searchValidations.length > 1) {
        obj.name = this.validation.fields[i].searchValidations
        this.validation.fields[i].searchValidationsOld = String(this.validation.fields[i].searchValidations)
      } else {
        this.validation.fields[i].isLoadingValidations = false

        return
      }

      getValidations(obj)
        .then((res) => {
          this.validation.fields[i].validations = res.data.data.items
          this.validation.fields[i].isLoadingValidations = false
          this.validation.fields[i].searchValidations = obj.name
        })
        .catch((err) => {
          this.validation.fields[i].isLoadingValidations = false
          this.err = err
        })
    },
    formattedRuleTypes (i, n) {
      const selectedTypes = this.validation.fields[i].rules.map((x) => x.type)

      if (['NUMBER', 'INTEGER', 'DOUBLE', 'LONG'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesNumber.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (this.validation.fields[i].dataType === 'STRING') {
        return this.ruleTypesString.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (['FILE_JSON', 'FILE_XML'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesFile.map((x) => {
          return { text: this.$lang.status[x], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      if (['ARRAY'].includes(this.validation.fields[i].dataType)) {
        return this.ruleTypesFile.map((x) => {
          return { text: this.$lang.status[`${x}_ARRAY`], value: x }
        }).filter((x) => !selectedTypes.includes(x.value) || x.value === this.validation.fields[i].rules[n].type)
      }

      return []
    },
    deleteRow(i) {
      this.validation.fields.splice(i, 1)
    },
    deleteRule(i, n) {
      this.validation.fields[i].rules.splice(n, 1)
    },
    fetchHistory(options) {
      if (!this.validation.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.validation.id
      obj.resourceType = 'VALIDATION_RULE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreValidation(data) {
      this.validation = data

      if (this.validation && this.validation.roles) {
        this.editRolesIds = this.validation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.validation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.validation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

        if (this.validation && this.validation.includes && this.validation.includes.length > 0) {
          this.includesAnotherValidation = true
        }
      }
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {
      this.loading = true
      this.lock = true

      let res = null

      const { id } = this.validation

      if (!this.isEdit) {
        delete this.validation.id
      }
      delete this.validation.createdOn
      delete this.validation.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.validation.roles = tempRoles

      this.validation.fields.forEach((field, index) => {
        field.rules.forEach((rule) => {
          if (['MAX_VALUE', 'MIN_VALUE', 'MAX_LENGTH', 'MIN_LENGTH', 'MIN_SIZE', 'MAX_SIZE'].includes(rule.type)) rule.value = Number(rule.value)
        })
        if (field.validationRule && field.validationRule.id) {
          field.validationRuleId = field.validationRule.id
          delete field.validationRule
        }

        if (['BOOLEAN', 'TIMESTAMP', 'INTEGER', 'FILE_JSON', 'FILE_XML', 'ARRAY'].includes(field.dataType) && field.genericSubType !== 'OBJECT') this.validation.fields[index].validationRuleId = null
        if (field.genericSubType === '') field.genericSubType = null
      })

      try {

        res = this.isEdit ? await updateValidation({ id, request: this.validation }) : await createValidation({ request: this.validation })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.validationUpdate : this.$lang.errors.validationCreate)
          setTimeout(() => this.err = '', 5000)
          this.loading = false
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.validationUpdated : this.$lang.success.validationCreated
        this.loading = false
        this.lock = false

        if (!this.isEdit) {
          this.$router.push({
            name: 'validationEdit',
            params: { id: res.data.data.id }
          })
        }

      } catch (err) {
        this.err = err
        this.loading = false
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
<style lang="scss">
.side-border {
  &-left {
    border-left: 1px solid var(--v-secondary-base);
  }
  &-right {
    border-right: 1px solid var(--v-secondary-base);
  }
}

.hideNumberArrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
